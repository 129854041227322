import { createPopup } from '@components/CreatePopup';
import L from 'leaflet';
import 'react-leaflet-markercluster';
import { createGreenIcon, createYellowIcon } from './create-icons';
import { getDataByGuid, transform_json } from './helperFunctions';

/**
 * - clear map
 * - create new Popups and Cluster
 **/
export async function createPopups({
  map,
  data,
  searchResult,
  history = 'history',
}) {
  /* clear map */
  map.eachLayer(function (layer) {
    if (layer instanceof L.MarkerClusterGroup) {
      map.removeLayer(layer);
    }
  });

  //align popup to center of map and screen
  map.on('popupopen', function (e) {
    // find the pixel location on the map where the popup anchor is
    var px = map.project(e.popup._latlng);
    // find the height of the popup container, divide by 2 to centre, subtract from the Y axis of marker location
    px.y -= e.popup._container.clientHeight / 3;
    // pan to new center
    map.panTo(map.unproject(px), { animate: true });
  });

  // Build Icons and Cluster
  /** @type {object} */
  let yellowLayerOptions = {
    pointToLayer: createYellowIcon,
    onEachFeature: (feature, layer) => createPopup(feature, layer, history),
    bubblingMouseEvents: false,
  };

  let greenLayerOptions = {
    pointToLayer: createGreenIcon,
    onEachFeature: (feature, layer) => createPopup(feature, layer, history),
    bubblingMouseEvents: false,
  };

  /** @type {object} */
  let clusterLayer = L.markerClusterGroup({
    iconCreateFunction: function (cluster) {
      let childCount = cluster.getChildCount();
      let childMarkers = cluster.getAllChildMarkers();

      // Check if any child marker has a title of "Suchstandort"
      let hasSuchstandort = childMarkers.some(function (marker) {
        return marker.options.title === 'Suchstandort';
      });

      // Set the class name based on the presence of "Suchstandort"
      let className = 'marker-cluster';
      if (hasSuchstandort) {
        className += ' search-cluster';
      } else {
        className += ' lokal';
      }

      return new L.DivIcon({
        html: '<div><span>' + childCount + '</span></div>',
        className,
        iconSize: new L.Point(40, 40),
      });
    },
  });

  const dataWithoutSearchResult = () =>
    data?.filter((item) => item.guid !== searchResult);

  const dataWithSearchResult = () =>
    data?.filter((item) => item.guid === searchResult);

  // If there is a search result, add the result to the clusterLayer without the search result
  clusterLayer.addLayer(
    L.geoJSON(
      transform_json(searchResult ? dataWithoutSearchResult() : data),
      yellowLayerOptions
    )
  );

  // create a seach-result icon outside the clusterlayer -> not affected by the filter
  if (!!searchResult) {
    let d = [];
    if (dataWithSearchResult.length > 0) {
      d = dataWithSearchResult();
    } else {
      const resp = await getDataByGuid(searchResult);
      resp.guid = searchResult;
      d = resp;
    }

    L.geoJSON(transform_json([d]), greenLayerOptions).addTo(map);
  }
  map.addLayer(clusterLayer);
}
