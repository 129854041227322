import { createPopups } from '@lib/create-popups';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AngebotContext } from '../../context/AngebotContext';
import Search from '../Search';
import { styles } from './FilterComponent-styles';
import FilterTree from './FilterTree';
import SidebarCard from './SidebarCard';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import { getCategoryDetails } from '../../lib/helperFunctions';
import { Colours } from '../../theme';

const filter_detail_data_fields = {
  /* category name in "Werte"-Endpoint :  description in the Frontend */
  Kat_Was: 'Einrichtungen',
  Kat_Barriere: 'Zugänglichkeit',
  Kat_Geschlechter: 'Geschlecht',
  Kat_Häufigkeit: 'Häufigkeit',
  Kat_Konstellation: 'Konstellation',
  Kat_Kosten: 'Kostenübernahmen',
  Kat_PersonAlter: 'Alter',
  Kat_PersonRolleDetail: 'Personen Rollen Details',
  Kat_PersonRollen: 'Rolle',
  Kat_Ort: 'Orte',
  Kat_Themen: 'Themen',
  Kat_Zielgruppe: 'Zielgruppe',
  Sprache: 'Sprache',
};

// The two APIs angebotedoc/werte and angebotedoc/filter have different names for the categories:
const kats_translations = {
  /* category name in "Werte"-Endpoint :  category name in "filter"-Endpoint */
  Kat_Was: 'kat_Was',
  Kat_Zielgruppe: 'kat_Zielgruppe',
  Kat_Geschlechter: 'kat_Geschlechter',
  Kat_Konstellation: 'kat_Konstellationen',
  Kat_Kosten: 'kat_Kosten',
  Kat_PersonAlter: 'kat_PersonAlter',
  Kat_PersonRolleDetail: 'kat_PersonRolleDetail',
  Kat_PersonRollen: 'kat_PersonRollen',
  Kat_Ort: 'kat_Orte',
  Kat_Themen: 'kat_Themen',
  Kat_Barriere: 'kat_Barrieren',
  Kat_WasDetail: 'kat_WasDetails',
  Sprache: 'kat_Sprachen',
};

export const katsWithoutDetails = ['Psychosomatische Reha'];

// filter legacy stuff
const not_visible_kats = [
  'Beratung & Dialog',
  'Psychiatrien',
  'Reha-Angebote',
  'Psychotherapie',
  'Alternative Krisenbegleitung',
  'Alternative Krisenbegleitung zu Kliniken',
  'Altern. Behandler',
  'durch Dich',
];

/** Filter **/
class Filter extends Component {
  map = this.props.map;
  history = this.props.history;
  classes = this.props.styles;
  static contextType = AngebotContext;
  handleDrawerClose = this.props.handleDrawerClose;

  state = {
    isToggleOn: false,
    isSmallScreen: false,
    zoom: 12,
    filter_groups: [],
    kats_data: {},
    loading: false,
    expanded: {},
    activeAccordion: null,
    shouldScroll: false,
    queryParams: new URLSearchParams(this.props.location.search),
  };

  accordionRefs = {};

  // Get the current value of the 'filters' parameter
  currentFilters = this.state.queryParams.get('filters');

  // If the 'filters' parameter is present, split it into an array
  filtersArrayFromUrl = this.currentFilters
    ? this.currentFilters.split(',')
    : [];

  // create a object from the array for easier/quicker access
  checked = this.filtersArrayFromUrl.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});

  componentDidMount() {
    const mediaQuery = window.matchMedia('(max-width: 959px)');
    if (mediaQuery.matches) this.setState({ isSmallScreen: true });
    this.fetch_filter_kats();

    if (this.filtersArrayFromUrl.length > 0 && this.state.kats_data) {
      return this.loadFilterdData(this.filtersArrayFromUrl);
    }
    this.fetch_init_data();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activeAccordion &&
      this.accordionRefs[this.state.activeAccordion] &&
      this.state.shouldScroll
    ) {
      this.accordionRefs[this.state.activeAccordion].current.scrollIntoView({
        behavior: 'smooth',
      });

      this.setState({ shouldScroll: false });
    }

    const currentUrlQuery = new URLSearchParams(window.location.search);
    const currentfilters = currentUrlQuery.get('filters');
    const prevUrlQuery = prevState.queryParams.get('filters');

    if (
      currentfilters !== prevUrlQuery ||
      this.state.kats_data !== prevState.kats_data
    ) {
      const filtersArrayFromUrl = currentfilters
        ? currentfilters.split(',')
        : [];

      // Update the 'checked' object
      const actualChecked = filtersArrayFromUrl.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {});

      // create payload for search from selected filters
      const selectedFilterSearchData = {};
      const checked = actualChecked;
      const categoryData = this.state.kats_data;

      if (
        categoryData &&
        typeof categoryData === 'object' &&
        checked &&
        typeof checked === 'object'
      ) {
        Object.keys(this.state.kats_data).forEach((key) => {
          const translated_key = kats_translations[key];
          selectedFilterSearchData[translated_key] = this.state.kats_data[
            key
          ].filter((item) => actualChecked[item]);
        });

        this.context.setMapData({
          ...this.context.mapData,
          selectedFilterSearchData,
        });

        this.setState({
          queryParams: currentUrlQuery,
        });
      }
    }
  }

  /** Lade Ungefilterte Daten, erstelle Popups **/
  fetch_init_data() {
    axios
      .get(process.env.REACT_APP_API_URL + '/angebote/zoom/' + this.state.zoom)
      .then((resp) => {
        // müssen 2 Operationen sein

        const { setMapData, mapData } = this.context;
        // Filter out Angebote with eins_aktiv = false

        const activeAngebote = resp.data.filter(
          (angebot) =>
            angebot.einsAktiv &&
            (this.state.queryParams.get('region') === 'Deutschlandweit'
              ? angebot.einsDeutschlandweit
              : true)
        );

        setMapData({
          ...mapData,
          angebot: activeAngebote,
          selectedFilterSearchData: {},
          searchValue: '',
        });

        this.setState({
          layers: createPopups({
            map: this.map,
            data: activeAngebote,
            history: this.history,
          }),
        });
        this.setState({ loading: false });
      })
      .catch((errors) => {
        alert('Fehler beim Datenabruf: ' + errors);
        this.setState({ loading: false });

        console.error(errors);
      });
  }

  /** Toggle Filter Options */
  fetch_filter_kats() {
    /** @type {promise} */
    let werte_data = axios.get(
      process.env.REACT_APP_API_URL + '/angebotedoc/werte'
    );

    axios
      .all([werte_data])
      .then(
        axios.spread((...resp) => {
          this.setState({ kats_data: this.state.kat_was_details });
          this.buildFilter(resp);
        })
      )
      .then(() => {
        const { filtersArrayFromUrl } = this;

        if (filtersArrayFromUrl.length > 0 && this.state.kats_data) {
          return this.loadFilterdData(filtersArrayFromUrl);
        }
      })
      .catch((errors) => {
        alert('Fehler beim Datenabruf: ' + errors);
        console.error(errors);
      });
  }

  /** Toggle Filter Options */
  /** Update Filter Opts on checkbox change **/
  handleCheckboxChange = (input) => {
    const queryParams = this.state.queryParams; // always new
    const filtersArrayFromUrl = queryParams.get('filters')
      ? queryParams.get('filters').split(',')
      : [];
    // input can be string or event or array
    let name = '';
    switch (typeof input) {
      case 'string':
        name = input;
        break;
      case 'object':
        Array.isArray(input) ? (name = input[0]) : (name = input.target.name);
        break;
      default:
        break;
    }

    if (typeof name === 'string') {
      // uncheck sub kats if main kat is touched
      this.state.kat_was_details.forEach((det) => {
        if (det.bedingung1 === name) {
          const isSubitemSelected = filtersArrayFromUrl.indexOf(det.wert);

          if (isSubitemSelected !== -1) {
            // Value exists, so remove it
            filtersArrayFromUrl.splice(isSubitemSelected, 1);
          }
        }
      });

      const indexOfMainFilter = filtersArrayFromUrl.indexOf(name);

      if (indexOfMainFilter !== -1) {
        // Value exists, so remove it
        filtersArrayFromUrl.splice(indexOfMainFilter, 1);
      } else {
        // Value doesn't exist, so add it
        filtersArrayFromUrl.push(name);
        // also add subitems
        this.state.kat_was_details.forEach((det) => {
          if (det.bedingung1 === name) {
            filtersArrayFromUrl.push(det.wert);
          }
        });
      }

      queryParams.set('filters', filtersArrayFromUrl.join(','));

      this.props.history.push({ search: queryParams.toString() });

      this.loadFilterdData(filtersArrayFromUrl);
    }
  };

  /**
   * apply Filter settings and (re-)load data
   */
  loadFilterdData(filtersArrayFromUrl) {
    const createPayload = () => {
      /** Create empty payload **/

      // Update the 'checked' object
      const actualChecked = filtersArrayFromUrl.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {});

      // create payload for search from selected filters
      const selectedFilterSearchData = {};
      const categoryData = this.state.kats_data;

      Object.keys(categoryData).forEach((key) => {
        const translated_key = kats_translations[key];

        selectedFilterSearchData[translated_key] = categoryData[key].filter(
          (item) => actualChecked[item]
        );
      });

      return selectedFilterSearchData;
    };

    /**
     * @param {function(payload: string)} payload - JSON string.
     */

    const selectedFilterSearchData = createPayload();

    const getData = (searchPayload) => {
      const hasPayloadItem = Object.values(searchPayload).some(
        (arr) => arr.length > 0
      );

      this.setState({ loading: true });

      if (!hasPayloadItem) {
        // Filter has NO selections
        this.fetch_init_data();
      } else {
        const searchParams = new URLSearchParams(this.props.location.search);
        const region = searchParams.get('region');

        const actualKatWas = selectedFilterSearchData?.kat_Was.filter((kat) =>
          katsWithoutDetails.includes(kat)
        );

        axios
          .post(
            process.env.REACT_APP_API_URL +
              '/angebote/angebotmapviewmodelscount/',
            { ...selectedFilterSearchData, kat_Was: actualKatWas ?? [] } //never filter for Kat_Was with API v2, except for the katsWithoutDetails
          )
          .then((resp) => {
            const count = resp.data;
            axios
              .post(
                process.env.REACT_APP_API_URL +
                  '/angebote/angebotmapviewmodels ',
                {
                  angebotFilter: {
                    ...selectedFilterSearchData,
                    kat_Was: actualKatWas ?? [],
                  },
                  take: count,
                }
              )
              .then((resp) => {
                const angebotData = resp.data;
                const { setMapData, mapData } = this.context;
                const activeAngebote = angebotData.filter(
                  (angebot) =>
                    angebot.einsAktiv &&
                    (region === 'deutschlandweit'
                      ? angebot.einsDeutschlandweit
                      : true)
                );

                setMapData({
                  ...mapData,
                  angebot: activeAngebote,
                  selectedFilterSearchData,
                  searchData: activeAngebote,
                });
              })
              .catch((errors) => {
                console.error(errors);
              })
              .finally(() => {
                const { mapData } = this.context;

                this.setState({
                  layers: createPopups({
                    map: this.map,
                    data: mapData.angebot,
                    history: this.history,
                  }),
                });
              });
          })
          .catch((errors) => {
            console.error(errors);
          });
      }
    };

    getData(selectedFilterSearchData);
  }

  /** Build initial Filter Options **/
  buildFilter(resp) {
    let werte = resp[0].data;

    let kats_array = Object.keys(kats_translations);

    // Leon: I have made the following 10 lines in order to leave Daniels Code mostly untouched
    // Perhaps there is a way to do this more elegantly
    let kats = {};
    let katWithInfoText = [];
    kats_array.forEach((kat) => {
      let kat_arr_temp = werte.filter((value) => {
        return kat === value.werteliste;
      });
      let kat_temp2 = [];

      kat_arr_temp.forEach((kat1) => {
        kat_temp2.push(kat1.wert);
        katWithInfoText.push({
          wert: kat1.wert,
          infotext: kat1.infotext,
          anmerkung: kat1.anmerkung,
        });
      });
      kats[kat] = kat_temp2;
    });

    let populated_kats = Object.keys(kats).filter((kat) => {
      return kats[kat].length > 0;
    });

    let sub_kats = kats.Kat_WasDetail;
    this.setState({
      kat_was_details: werte.filter((wert) => {
        let ret = sub_kats.filter((s) => {
          return s === wert.wert;
        });
        return ret.length > 0;
      }),
    });

    /** build filter groups **/
    populated_kats.forEach((kat) => {
      //** Order Kategories **/
      const putToBegin = (fg) =>
        this.setState({
          filter_groups: Object.assign(fg, this.state.filter_groups),
        });
      const putToEnd = (fg) =>
        this.setState({
          filter_groups: Object.assign(this.state.filter_groups, fg),
        });

      if (filter_detail_data_fields.hasOwnProperty(kat)) {
        let opts = [];
        let fg = {};
        let toEnd = true;

        kats[kat].map((k) => {
          fg = {};

          if (kat === 'Kat_Was') {
            // soll an de Anfang der Filter Box
            toEnd = false;

            const { name, position, icon } = getCategoryDetails(k);

            let relevant_kat_details = this.state.kat_was_details.filter(
              (kat) => {
                return kat.bedingung1 === name;
              }
            );

            // let fg_details = relevant_kat_details.map((k) => k.wert);
            let fg_details = relevant_kat_details.map(
              ({ wert, infotext, anmerkung }) => {
                return {
                  wert,
                  infotext,
                  anmerkung,
                };
              }
            );
            if (!not_visible_kats.includes(k))
              opts.push({
                icon,
                name: name,
                infotext: katWithInfoText,
                pos: position,
                details: fg_details,
              });
          } else {
            // Ohne Icons
            if (!not_visible_kats.includes(k))
              opts.push({ name: k, infotext: katWithInfoText });
          }

          opts = _.sortBy(opts, 'pos');
          fg[filter_detail_data_fields[kat]] = opts;

          // reset chekbox states
          this.setState({
            checked: opts.reduce(
              (options, option) => ({ ...options, [option.name]: false }),
              {}
            ),
          });
          return null;
        });

        toEnd ? putToEnd(fg) : putToBegin(fg);
      }
    });

    this.setState({ kats_data: kats });
  }

  render() {
    const { classes, row } = this.props;
    const keys = Object.keys(this.state.filter_groups);

    // const queryParams = new URLSearchParams(window.location.search);
    const currentFilters = this.state.queryParams.get('filters');
    const filtersArrayFromUrl = currentFilters ? currentFilters.split(',') : [];
    const hasActiveFilters = filtersArrayFromUrl.length > 0;

    // Update the 'checked' object
    const actualChecked = filtersArrayFromUrl.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});

    /* Main Filter Cats */
    const filters = keys.map((group, i) => {
      if (group === 'Einrichtungen') {
        let opts = this.state.filter_groups[group];

        const sortedKatWasDetailByAnmerkung = opts.map((opt) => {
          const sortedDetails = opt.details.sort((a, b) =>
            a.anmerkung.localeCompare(b.anmerkung)
          );

          return { ...opt, details: sortedDetails };
        });

        return (
          <FormGroup
            classes={row !== 'no' ? { root: classes.flexRow } : { root: 'foo' }}
            key={i}
          >
            <FilterTree
              data={sortedKatWasDetailByAnmerkung}
              filtersArrayFromUrl={filtersArrayFromUrl}
              checked={actualChecked}
              changeHandler={this.handleCheckboxChange}
            />
          </FormGroup>
        );
      }
      return null;
    });

    // Extended filter kats
    const ext_filters = keys.map((group) => {
      if (group !== 'Einrichtungen' && group !== 'Details') {
        const optsInfo = this.state.filter_groups[group].map(
          ({ infotext, ...rest }) => infotext
        );

        const groupSelected = {};

        let opts = this.state.filter_groups[group].map(
          ({ infotext, ...rest }) => {
            // keep the count of each accordion checked item
            if (actualChecked[rest.name] && !groupSelected[group]) {
              groupSelected[group] = actualChecked[rest.name];
            }

            return { ...rest, group };
          }
        );

        // get anmerkung and sort by anmerkung
        const optsWithAnmerkung = opts.map((info) => {
          const matchingObj1 = optsInfo[0].find((o) => o.wert === info.name);
          if (matchingObj1) {
            return { ...info, anmerkung: matchingObj1.anmerkung };
          }
          return info;
        });

        const sortedbyAnmerkung = optsWithAnmerkung.sort((a, b) =>
          a.anmerkung.localeCompare(b.anmerkung)
        );

        if (!this.accordionRefs[group]) {
          this.accordionRefs[group] = React.createRef();
        }

        return (
          <FormGroup
            classes={row !== 'no' ? { root: classes.optGrp } : { root: 'foo' }}
            key={group}
          >
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                boxShadow: 'none',
                minHeight: 'unset',
              }}
              className={classes.accordion}
              expanded={this.state.expanded[group] ?? false}
              onChange={() => {
                this.setState({
                  activeAccordion: group,
                  shouldScroll: true,
                });
                this.setState({
                  expanded: {
                    ...this.state.expanded,
                    [group]: !this.state.expanded[group],
                  },
                });
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${group}-content`}
                id={`${group}-header`}
                sx={{
                  padding: '0',
                  margin: '0',
                }}
                ref={this.accordionRefs[group]}
              >
                <Typography
                  className={classes.optHeading}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{group}</span>
                  {groupSelected[group] ? (
                    <CheckIcon
                      fontSize='small'
                      sx={{
                        marginTop: '-4px',
                        marginLeft: '8px',
                      }}
                    />
                  ) : null}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 0,
                }}
              >
                {sortedbyAnmerkung.map((option) => {
                  return (
                    <FormControlLabel
                      label={option.name}
                      key={option.name}
                      className={`${option.iconClass} ${classes.form} ${classes.optLabel}`}
                      control={
                        <Checkbox
                          checked={actualChecked[option.name] ? true : false} // makes it controlled
                          onChange={(e) => {
                            this.handleCheckboxChange(e);
                          }}
                          name={option.name}
                        />
                      }
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </FormGroup>
        );
      }
      return null;
    });

    return (
      <>
        {!this.props.isMobile && (
          <Search
            angebotFilter={this.context.mapData?.selectedFilterSearchData}
            handleDrawerClose={this.handleDrawerClose}
          />
        )}

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          marginTop={1.5}
          marginBottom={0.5}
        >
          <Typography variant='h5' classes={{ root: classes.h5 }}>
            Filter {hasActiveFilters && `(${filtersArrayFromUrl.length})`}
          </Typography>
          {hasActiveFilters && (
            <Button
              onClick={() => {
                const queryParams = this.state.queryParams;
                queryParams.delete('filters');
                this.props.history.push({ search: queryParams.toString() });
                this.fetch_init_data();
              }}
              sx={{
                paddingY: '0',
                color: Colours._lightGrey,
                fontSize: '.8rem',
              }}
            >
              <span>alle Filter löschen</span>

              <CloseIcon
                fontSize='small'
                sx={{
                  fontSize: '.9rem',
                  color: Colours._lightGrey,
                }}
              />
            </Button>
          )}
        </Box>
        <Box
          padding='0 16px'
          bgcolor='white'
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              justifyItems: 'center',
              minHeight: '4px',
              /* minHeight: '45px', */
              borderBottom: `1px solid ${Colours._lightGrey}`,
            }}
          >
            {/* Leon: I have deaktivated the "deutschlandweit"-Filter, until the also the angebote without gps are displayed
                      After the activation, the minHeight of this box has to be set to 45px again
            {['regional', 'deutschlandweit'].map((region) => {
              return (
                <Box component='label' display='flex' alignItems='center'>
                  <Checkbox
                    checked={region === queryParams.get('region')}
                    name={region}
                    value={region}
                    onChange={(e) => {
                      const { mapData } = this.context;
                      const value = e.target.value;
                      queryParams.set('region', value);

                      this.props.history.push({
                        search: queryParams.toString(),
                      });

                      // Filter for Angebote with einsDeutschlandweit = true
                      const activeAngebote = mapData.angebot.filter(
                        (angebot) =>
                          angebot.einsAktiv &&
                          (value === 'deutschlandweit'
                            ? angebot.einsDeutschlandweit
                            : true)
                      );

                      this.setState({
                        layers: createPopups({
                          map: this.map,
                          data: activeAngebote,
                          history: this.history,
                        }),
                      });
                    }}
                    sx={{
                      padding: '0',
                      color: Colours._deepGreen,
                      '&.Mui-checked': {
                        color: Colours._deepGreen,
                      },
                    }}
                  />
                  <Box component='span' ml={0.2} mt={0.5}>
                    {region}
                  </Box>
                </Box>
              );
            })} */}
          </Box>
        </Box>
        <SidebarCard classes={classes.btmBox}>
          <Box mb={1}> {filters} </Box>
          {ext_filters}
        </SidebarCard>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Filter));
